<template>
  <div class="dcpic">
    <Loading v-if="isInitLoading" />
    <Header class="app-title" theme="dark" @click="goBack">
      <template>华夏AI</template>
    </Header>
    <div class="container">
      <div class="menu-title">
        <div @click="setPage($event, 1)" class="tag tag1 menu-title-active">
          创作
        </div>
        <div @click="setPage($event, 2)" class="tag tag2">任务</div>
        <div @click="setPage($event, 3)" class="tag tag3">历史</div>
      </div>
      <div v-if="page == 1" class="dcpic-page-1">
        <div class="dcpic-text">
          <img src="../../assets/images/dcpic/text.png" />
        </div>
        <div class="dcpic-title">
          <img src="../../assets/images/dcpic/title.png" />
        </div>
        <div class="dcpic-border">
          <textarea
            :placeholder="placeholder"
            v-model="textareaValue"
            @input="textareaChange"
          ></textarea>
        </div>
        <div class="dcpic-note">*建议输入英文，识别更精准</div>
        <div class="upload-pic">
          <div class="upload-pic-1">上传参考图（可选）</div>
          <input
            ref="fileInput"
            class="hide"
            type="file"
            multiple="multiple"
            accept="image/*"
            @change="uploadImg"
          />
          <div
            class="upload-pic-2 cursor"
            v-if="!uploadSrc"
            @click="onUploadPic"
          >
            <div class="upload-code-add">+</div>
            <div class="upload-code-add-1">点击上传参考图</div>
            <div class="upload-code-add-2">支持图片格式:JPG、PNG 不超过2MB</div>
          </div>
          <div class="upload-pic-3" v-else>
            <div><img :src="uploadUrl" /></div>
            <div>
              <div class="cursor" @click="reUploadPic">
                <span>重新上传</span>
              </div>
            </div>
          </div>
        </div>
        <div class="dcpic-botton-1" @click="startCreate()" v-if="buttonStatus">
          <div>开始生成</div>
        </div>
        <div class="dcpic-botton-2" v-else>
          <div>开始生成</div>
        </div>
      </div>
      <div v-if="page == 2" class="dcpic-page-2">
        <div class="page-2-note">*仅可查看30天内的任务</div>
        <van-pull-refresh v-model="isLoadingList" @refresh="onRefreshDo">
          <van-list
            v-model="isloadlist"
            finished-text="没有更多了"
            :finished="listfinished"
            @load="onLoadList"
            :immediate-check="false"
          >
            <div class="on-refresh">
              <div class="page-2-list">
                <div
                  v-for="(item, index) in datalist"
                  :key="index"
                  @click="showDetail(item)"
                >
                  <img :src="item.url" />
                  <div>{{ item.note }}</div>
                </div>
              </div>
              <div v-if="datalist.length == 0" class="none-img">
                <img src="../../assets/images/dcpic/none.png" />
                <div>还没作品，快去创作吧！</div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
      <div v-if="page == 3" class="dcpic-page-2">
        <div class="page-2-note">*已铸造的藏品</div>
        <van-pull-refresh
          v-model="isLoadingHistoryList"
          @refresh="onRefreshEnd"
        >
          <van-list
            v-model="isloadhistorylist"
            finished-text="没有更多了"
            :finished="historylistfinished"
            @load="onLoadHistoryList"
            :immediate-check="false"
          >
            <div class="on-refresh">
              <div class="page-2-list scroll-container-two">
                <div
                  v-for="(item, index) in dataHistoryList"
                  :key="index"
                  @click="showHistoryDetail(item)"
                >
                  <img :src="item.url" />
                  <div>{{ item.note }}</div>
                </div>
              </div>
              <div v-if="dataHistoryList.length == 0" class="none-img">
                <img src="../../assets/images/dcpic/none.png" />
                <div>还没作品，快去创作吧！</div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>
<script>
import CryptoJS from 'crypto-js'
import Axios from 'axios'
import { addBridgeForAndroidWebView } from 'sdbridge-android'

export default {
  name: 'aipiclist',
  data() {
    return {
      maxSize: 1024 * 1024 * 2,
      uploadSrc: null,
      uploadUrl: null,
      isInitLoading: true,
      platform: '',
      bridge: '',
      userId: '',
      waitTime: 10 * 60 * 1000,
      id: '',
      loadlist: false,
      listfinished: false,
      historylistfinished: false,
      isloadlist: false,
      isloadhistorylist: false,
      page: 1,
      listPage: 1,
      listHistoryPage: 1,
      limit: 10,
      textareaValue: '',
      buttonStatus: false,
      doingImg: '',
      datalist: [],
      dataHistoryList: [],
      money: 0,
      loading: '',
      isLoadingList: false, // 是否正在加载数据
      isLoadingHistoryList: false, // 是否正在加载数据
      placeholder:
        'For example: A teenager standing by the sea looks at the distance',
      domainUrl: 'https://images.weserv.nl/?url=',
      apiKey:
        'NY7II0HHL1NKSPgtMOhRCa9E5jYDPSKFTXoozYJSDmJuvSyYtsEiZK9meCaCd9KL',
      isAndroid:
        navigator.userAgent.indexOf('Android') > -1 ||
        navigator.userAgent.indexOf('Adr') > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    }
  },
  methods: {
    onUploadPic() {
      if (!this.token) {
        this.goLogin()
        return
      }
      this.$refs.fileInput.setAttribute('type', 'file')
      this.$refs.fileInput.click()
    },
    reUploadPic() {
      this.uploadSrc = null
      this.$refs.fileInput.setAttribute('type', 'text')
      this.onUploadPic()
    },
    async uploadImg(e) {
      this.loadfunc()
      const file = e.target.files[0]
      if (file.size > this.maxSize) {
        this.$toast('图片超出2M限制')
        return
      }
      // 通过 FormData 对象上传文件
      var formData = new FormData()
      formData.append('file', file)

      try {
        let response = await Axios.post('/discordapp/uploadImg', formData, {
          headers: { 'content-type': 'multipart/form-data' },
        })
        this.uploadSrc = response.data.src
        let img = new FileReader()
        img.readAsDataURL(e.target.files[0])
        img.onload = ({ target }) => {
          this.uploadUrl = target.result
        }
      } catch (e) {
        this.$toast('上传失败')
      }
      this.loading.close()
    },
    goBack() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -2 }))
        } else {
          this.bridge.callHandler(
            'jumptoapp',
            { key: -2 },
            function (response) {
              console.log(JSON.stringify(response))
            }
          )
        }
      } else {
        this.$router.back()
      }
    },
    textareaChange() {
      let textareaValue = this.textareaValue.trim()
      if (textareaValue.length > 0) {
        this.buttonStatus = true
      } else {
        this.buttonStatus = false
      }
    },
    loadWait() {
      this.loading = this.$toast.loading({
        message: '正在等待AI渲染，预计等待60秒。',
        forbidClick: true,
        duration: 0,
      })
    },
    loadfunc() {
      this.loading = this.$toast.loading({
        forbidClick: true,
        duration: 0,
      })
    },
    async startCreate() {
      // 精灵限制
      //   if (this.$route.query.token) {
      //     let createAuth = await this.$api.chatgptCreatePic({
      //       token: this.$route.query.token,
      //     })
      //     if (createAuth.code != 0) {
      //       this.$toast(createAuth.msg)
      //       return
      //     }
      //   }
      if (!this.token) {
        this.goLogin()
        return
      }
      let status = await this.getIssetPic()
      if (!status) {
        this.$toast('已有任务在创作中，请等待结束后重试！')
        return
      }
      this.loadWait()
      let textareaValue = this.textareaSet()
      let userId = this.userId
      let addurl = this.uploadSrc
      if (textareaValue.length > 0) {
        let data = this.setToken({
          prompt: textareaValue,
          userId: userId,
          addurl: addurl,
        })
        let response = await Axios.post('/discordapp/addPrompt', { data: data })
        if (response.status == 200) {
          this.loading.close()
          await this.lookVideo();
          await this.onRefreshDo()
          this.setTag(2)
        }
      }
    },
    async lookVideo() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: 12 }));
        } else {
          this.bridge.callHandler(
            'jumptoapp',
            { key: 12 },
            function (response) {
              console.log(JSON.stringify(response));
            },
          );
        }
      }
    },
    textareaSet() {
      let text = this.textareaValue.trim()
      text = text.replace(/[\u00a0]/g, ' ')
      text = text.replace(/[\r\n]/g, ' ')
      text = text.replace(/ {2,}/g, ' ')
      return text
    },
    goLogin() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -1 }))
        } else if (this.isiOS) {
          this.bridge.callHandler(
            'jumptoapp',
            { key: -1 },
            function (response) {
              console.log(JSON.stringify(response))
            }
          )
        }
      } else {
        this.$router.push('/login')
        return false
      }
    },
    async getAnyUserinfo() {
      //余额信息
      let params = {
        token: this.token,
      }
      try {
        await this.$api.anyUserinfo(params).then((res) => {
          if (res.code == 0) {
            this.userId = res.data.id
            this.money = res.data.money
          } else {
            this.$toast('账号登陆异常')
          }
        })
      } catch (error) {
        this.userId = ''
        this.money = 0
      }
    },
    randomString(len) {
      var chars = '0123456789abcdefghijklmnopqrstuvwxyz'
      var result = ''
      for (var i = len; i > 0; --i)
        result += chars[Math.floor(Math.random() * chars.length)]
      return result
    },
    setToken(data) {
      data.timestamp = parseInt(Date.parse(new Date()))
      const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        this.apiKey,
        {
          mode: CryptoJS.mode.ECB, // 模式
          padding: CryptoJS.pad.Pkcs7, // 填充
        }
      ).toString()
      return ciphertext
    },
    async getIssetPic() {
      // let textareaValue = this.textareaValue.trim()
      let userId = this.userId
      if (userId) {
        let data = this.setToken({
          userId: userId,
          // textareaValue: textareaValue,
        })
        let response = await Axios.post('/discordapp/getIssetPic', {
          data: data,
        })
        if (response.status == 200) {
          let num = response.data.data[0].num
          if (num == 0) {
            return true
          } else {
            return false
          }
        } else {
          this.$toast('网络请求错误')
        }
      } else {
        this.$toast('用户未登录')
      }
    },
    setPage(event, page) {
      let tags = document.querySelectorAll('.tag')
      tags.forEach((tag) => {
        tag.classList.remove('menu-title-active')
      })
      event.target.classList.add('menu-title-active')
      this.page = page
    },
    async getUserDoing() {
      let userId = this.userId
      let page = this.listPage
      let limit = this.limit
      let offset = (page - 1) * this.limit
      if (userId) {
        let data = this.setToken({
          userId: userId,
          limit: limit,
          offset: offset,
        })
        let response = await Axios.post('/discordapp/getUserDoing', {
          data: data,
        })
        if (response.status == 200) {
          if (response.data.data.length == 0) {
            this.listfinished = true
            return
          }
          let datalist = []
          for (const item of response.data.data) {
            let list = {}
            if (item.status == 1) {
              let dateNow = new Date().getTime()
              let createTime = new Date(
                item.create_at.replace(/-/g, '/')
              ).getTime()
              let waitTime = dateNow - createTime

              if (waitTime < this.waitTime) {
                list.note = '作品创作中'
                list.state = 1
                list.url = require('../../assets/images/dcpic/load.png')
              } else {
                list.note = '创作失败'
                list.state = 2
                list.url = require('../../assets/images/dcpic/error.png')
              }
            } else {
              list.state = 0
              list.note = '创作成功'
              list.status = item.status
              list.type = item.type
              list.id = item.id
              list.prompt = item.prompt
              list.messageId = item.message_id
              list.messageName = item.message_name
              list.url = this.domainUrl + item.url
            }
            datalist.push(list)
          }
          this.isLoadingList = false
          if (page == 1) {
            this.datalist = datalist
          } else {
            this.datalist = this.datalist.concat(datalist)
          }
        } else {
          this.$toast('网络请求错误')
        }
      } else {
        this.$toast('用户未登录')
      }
    },
    async getUserDoEnd() {
      let userId = this.userId
      let page = this.listHistoryPage
      let limit = this.limit
      let offset = (page - 1) * this.limit
      if (userId) {
        let data = this.setToken({
          userId: userId,
          limit: limit,
          offset: offset,
        })
        let response = await Axios.post('/discordapp/getUserDoEnd', {
          data: data,
        })
        if (response.status == 200) {
          if (response.data.data.length == 0) {
            this.historylistfinished = true
            return
          }
          let datalist = []
          for (const item of response.data.data) {
            let list = {}
            list.url = this.domainUrl + item.url
            list.prompt = item.prompt
            list.note = '藏品已铸造'
            datalist.push(list)
          }
          this.isLoadingHistoryList = false
          if (page == 1) {
            this.dataHistoryList = datalist
          } else {
            this.dataHistoryList = this.dataHistoryList.concat(datalist)
          }
        } else {
          this.$toast('网络请求错误')
        }
      } else {
        this.$toast('用户未登录')
      }
    },
    showDetail(item) {
      if (item.state == 1) {
        this.$toast('作品创作中...')
        return
      }
      if (item.state == 2) {
        return
      }
      this.$router.push({
        path: '/dcpicdetail',
        query: {
          type: item.type,
          id: item.id,
          showPrompt: item.prompt,
          showUrl: item.url,
          token: this.token,
          userId: this.userId,
          messageId: item.messageId,
          messageName: item.messageName,
          money: this.money,
          pid: this.pid,
          platform: this.platform,
        },
      })
    },
    setTag(tag) {
      let tags = document.querySelectorAll('.tag')
      tags.forEach((tag) => {
        tag.classList.remove('menu-title-active')
      })
      let myElement = document.querySelector('.tag' + tag)
      myElement.classList.add('menu-title-active')
      this.page = tag
    },
    showHistoryDetail(item) {
      this.$router.push({
        path: '/dcpicshow',
        query: {
          url: item.url,
          prompt: item.prompt,
          token: this.token,
          pid: this.pid,
          platform: this.platform,
        },
      })
    },
    async getLoopList() {
      let data = this.setToken({})
      let response = await Axios.post('/discordapp/getLoopList', {
        data: data,
      })
      if (response.status == 200) {
        for (const data of response.data.data) {
          let orderStatus = await this.getLoop(data.order_no)
          if (orderStatus) {
            await this.picPay(2, data.id)
          }
        }
      }
    },
    async getLoop(orderNo) {
      const query = {
        token: this.token,
        order_no: orderNo,
      }
      let e = await this.$api.loop(query)
      if (e.code != 100000) {
        return false
      }
      this.info = JSON.stringify(e.data)
      if (e.data.status === 2) {
        return true
      } else {
        return false
      }
    },
    async picPay(ispay, id) {
      let userPromptUrlId = id
      if (!userPromptUrlId || !ispay) {
        this.$toast('参数异常')
        return
      }
      let data = this.setToken({
        userPromptUrlId: userPromptUrlId,
        ispay: ispay,
      })
      await Axios.post('/discordapp/picPay', { data: data })
    },
    async init() {
      if (this.$route.query.tag) {
        this.setTag(this.$route.query.tag)
      }
      if (this.$route.query.id) {
        this.pid = this.$route.query.id
      }
      if (this.$route.query.platform) {
        this.platform = this.$route.query.platform
      }
      if (this.$route.query.token) {
        localStorage.setItem('token', this.$route.query.token)
        this.token = this.$route.query.token
      } else {
        this.token = localStorage.getItem('token')
      }

      await this.getAnyUserinfo()
      await this.getLoopList()

      this.onRefreshDo()
      this.onRefreshEnd()

      let bridge = window.WebViewJavascriptBridge
      if (!bridge) {
        console.log('TypeScript正在尝试挂载')
        addBridgeForAndroidWebView()
        //@ts-ignore
        bridge = window.WebViewJavascriptBridge
        if (bridge) {
          console.log(
            'window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!'
          )
          this.bridge = bridge
        }
      } else {
        this.bridge = bridge
        console.log('window.WebViewJavascriptBridge 挂载成功！！')
      }

      this.isInitLoading = false
    },
    async onRefreshDo() {
      this.listPage = 1
      this.listfinished = false
      await this.getUserDoing()
    },
    async onRefreshEnd() {
      this.listHistoryPage = 1
      this.historylistfinished = false
      await this.getUserDoEnd()
    },
    async onLoadList() {
      this.listPage++
      await this.getUserDoing()
      this.isloadlist = false
    },
    async onLoadHistoryList() {
      this.listHistoryPage++
      await this.getUserDoEnd()
      this.isloadhistorylist = false
    },
  },
  created() {
    this.isInitLoading = true
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="less" scoped>
.dcpic {
  width: 100%;
  color: #ffffff;
  background: #02192b;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: auto;
}
.app-title {
  background: #23272a !important;
}
.menu-title {
  display: flex;
  height: 40px;
  text-align: center;
  height: 40px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #cecece;
  line-height: 40px;
  > div {
    width: 33.3%;
    height: 100%;
  }
}
.menu-title-active {
  color: #aa29f2;
  font-weight: 500;
  border-bottom: 3px solid #aa29f2;
}
.dcpic-page-1 {
  padding: 0 16px;
  box-sizing: border-box;
  text-align: center;
  background-image: url('../../assets/images/dcpic/beijing.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.dcpic-text > img {
  width: 262px;
  margin-top: 25px;
}
.dcpic-title > img {
  width: 294px;
  height: 25px;
  margin-top: 32px;
}
.dcpic-border {
  margin-top: 15px;
  background-image: url('../../assets/images/dcpic/border.png');
  background-repeat: no-repeat;
  background-size: 342px 278px;
  width: 342px;
  height: 278px;
  ::placeholder {
    color: #594b82;
  }
  > textarea {
    background-color: rgba(255, 255, 255, 0);
    width: 310px;
    height: 243px;
    margin: 18px auto;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #f6edff;
    line-height: 21px;
    border: 0;
    resize: none;
  }
}
.dcpic-note {
  height: 24px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #f6edff;
  line-height: 21px;
  margin-top: 10px;
  text-align: left;
}
.dcpic-botton-1 {
  width: 100%;
  height: 44px;
  margin-top: 27px;
  margin-bottom: 14px;
  background: linear-gradient(44deg, #440edc 0%, #9c2df5 100%);
  border-radius: 5px;
  > div {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 44px;
  }
}
.dcpic-botton-2 {
  width: 100%;
  height: 44px;
  margin-top: 27px;
  background: #4a3e6e;
  border-radius: 5px;
  > div {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 44px;
  }
}
.on-refresh {
  min-height: 100vh;
}
.dcpic-page-2 {
  padding: 0 16px;
  box-sizing: border-box;
}
.page-2-note {
  height: 42px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #faf1ff;
  line-height: 42px;
}
.page-2-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  > div {
    width: 167px;
    height: 190px;
    border: 1px solid #ccbadf;
    background: linear-gradient(127deg, #a127e7 43%, #450fdc 100%);
    box-sizing: border-box;
    margin-bottom: 10px;
    > img {
      width: 155px;
      height: 155px;
      display: block;
      margin: 5px;
    }
    > div {
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
      text-align: center;
    }
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
.dcpic::-webkit-scrollbar,
.page-2-list::-webkit-scrollbar {
  display: none;
}
.none-img {
  > img {
    display: block;
    margin: 68px auto 0;
    width: 102px;
    height: 105px;
  }
  > div {
    text-align: center;
    margin-top: 22px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #827aa0;
    line-height: 14px;
  }
}
.upload-pic-1 {
  text-align: left;
  margin: 5px 0;
  height: 23px;
  font-size: 15px;
  font-family: ziticqtezhanti;
  font-weight: 400;
  font-style: italic;
  color: #4ef9ff;
  line-height: 23px;
}
.upload-pic-2,
.upload-pic-3 {
  background: #0e1b3b;
  border: 1px solid #5012dd;
  border-radius: 5px;
  font-size: 12px;
  height: 100px;
}
.upload-code-add {
  font-size: 61px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #5814df;
  height: 51px;
  line-height: 51px;
}
.upload-code-add-1 {
  height: 13px;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #5814df;
  line-height: 13px;
  margin: 5px 0;
}
.upload-code-add-2 {
  height: 13px;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #4a3e6e;
  line-height: 13px;
  margin: 5px 0;
}
.upload-pic-3 {
  display: flex;
  justify-content: space-between;
  > div:first-child > img {
    width: 78px;
    height: 78px;
    margin-top: 10px;
    margin-left: 10px;
  }
  > div:last-child > div {
    width: 85px;
    height: 40px;
    background: #9530f7;
    border-radius: 3px;
    margin-right: 30px;
    margin-top: 30px;
    > span {
      width: 55px;
      height: 40px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #fff;
      line-height: 40px;
    }
  }
}
.cursor {
  cursor: pointer;
}
.hide {
  display: none;
}
</style>
